/* Sidebar component */

#sidebar {
  background-color: var(--dark);
  width: var(--sidebar-width);
  min-width: fit-content;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden auto;
  transition: all .3s ease-in-out;
}

#sidebar-logo {
  display: block;
  padding: 30px 0;
  margin: auto;
  text-align: center;
}

#sidebar-logo img {
  height: 30px;
}

.d-sidebar-item {
  display: block;
  box-sizing: border-box;
  padding: 10px 20px;
  border-left: 3px solid transparent;
  color: #FFF;
  transition: all .3s ease-in-out;
}

.d-sidebar-item.d-nav-item {
  border-left: 3px solid transparent;
}

.d-sidebar-item:hover:not(.disabled) {
  color: #FFF !important;
  background-color: rgba(255, 255, 255, .1);
  text-decoration: none;
}

.d-sidebar-item.d-nav-item.active {
  border-left: 3px solid #fff;
}

.d-sidebar-item.d-nav-item.disabled {
  color: rgba(255, 255, 255, .4);
}

#d-sidebar-signout {
  padding: 10px 20px;
  display: block;
}