.check-user-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #3B3C49; */
  background: var(--dark);
  z-index: 10000;
  color: #fff;
  display: flex;
  align-items: stretch;
}

.check-user-inner {
  display: flex;
  /* background-color: rgba(255, 255, 255, .2); */
  background: linear-gradient(to right, transparent 0%, var(--dark-lighter) 35%, transparent 40%);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: checkUserLoading;
  animation-timing-function: ease-in-out;
}

@keyframes checkUserLoading {
  0% {
    background-position: -40vw 0;
  }
  100% {
    background-position: 60vw 0;
  }
}
