/* Topbar component */

#topbar {
  background-color: var(--dark);
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  height: var(--topbar-height);
  z-index: 3;
}

#topbar-logo img {
  height: 30px;
}

#topbar-menu-button {
  color: rgba(255, 255, 255, .7);
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--topbar-height);
  height: var(--topbar-height);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

#topbar-menu-button:hover {
  color: rgba(255, 255, 255, 1);
}