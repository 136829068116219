// $borderColor: rgba(0,0,0,.123);
$corn-light: #fff0b3;

.row-card {
  padding: 25px 30px;
  transition: all .2s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-card.selected {
  background-color: $corn-light;
}

.d-block-list-link {
  display: block;
  color: black !important;
  text-decoration: none !important;
}

.d-block-list-link:hover .row-card {
  border-color: rgba($color: #000000, $alpha: .3);
}

.row-card .edit-button {
  opacity: .2;
  display: block;
  padding: 0 5px;
  transition: all .2s ease-in-out;
  border-radius: 50%;
}

.row-card:hover .edit-button {
  opacity: 1;
}

.row-card .edit-button:hover {
  background-color: rgba($color: #000000, $alpha: .2);
}
