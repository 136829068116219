@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
/* Font Awesome */

/* @import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css); */

:root {
  --sidebar-width: 230px;
  --topbar-height: 60px;
  --root-side-padding: 0;
  
  /* colors */
  --dark: #29272B;
  --dark-lighter: #312f33;
  --corn: #ffcc00;
  --corn-darker: #D9AD00;
}

body {
  background-color: #f9f9f9;
}

html, body {
  height: 100%;
  margin: 0;
}

.visible {
  opacity: 1;
}

#root {
  display: flex;
  flex-direction: row;
  padding-right: var(--root-side-padding);
  /* padding-bottom: 50px; */
  padding-left: var(--root-side-padding);
  height: 100%;
  /* margin-bottom: -50px; */
}

#content {
  padding-top: 30px;
  padding-bottom: 100px;
  overflow: auto;
}

#footer, .footer-push {
  height: 50px;
}

#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b3b3;
}

/* MyPageTitle component */

.d-page-title {
  margin-bottom: 30px;
}
