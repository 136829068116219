// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$gray-100: #FAFAFA;
$gray-200: #F2F1F3;
$gray-300: #D9D6DC;
$gray-400: #9698a6;
$gray-500: #7e7f91;
$gray-600: #676979;
$gray-700: #7B777E;
$gray-800: #47434B;
$gray-900: #29272B;
$black: #000;

$corn: #FFCC00;
$corn-darker: #D9AD00;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$facebook: #4267b2;
$google: #db4437;

$colors: (
  "corn": $corn,
  "corn-darker": $corn-darker,
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-700,
  "gray-dark": $gray-800,
  "gray-darker": $gray-900,
  "facebook": $facebook,
  "google": $google,
);

$corn: $corn;
$corn-darker: $corn-darker;
$primary: $corn;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$darker: $gray-900;
$facebook: $facebook;
$google: $google;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
  "corn": $corn,
  "corn-darker": $corn-darker,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "darker": $darker,
  "facebook": $facebook,
  "google": $google,
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-200;
$body-color:                $gray-900;


$font-family-sans-serif: 'Roboto', sans-serif;

$font-weight-lighter:         lighter;
$font-weight-light:           100;
$font-weight-normal:          300;
$font-weight-bold:            500;
$font-weight-black:           700;
$font-weight-bolder:          bolder;



$btn-font-weight:             $font-weight-normal;


// Links
//
// Style anchor elements.

$link-color:                              $body-color;
$link-font-weight:                        $font-weight-bold;
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;


$input-color:                           $gray-900;

