/* Custon breakpoint for small screens */

@media (max-width: 700px) {
  #root {
    padding-left: var(--root-side-padding);
    padding-top: var(--topbar-height);
  }
  #sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    align-items: justify;
    padding-top: calc(var(--topbar-height) + 30px);
    top: -100%;
    bottom: 100%;
  }
  #sidebar.expanded {
    top: 0;
    bottom: 0;
  }
  #sidebar-logo {
    display: none;
  }
  .d-sidebar-item {
    text-align: center;
    padding: 15px;
  }
  .d-sidebar-item.d-nav-item, .d-sidebar-item.d-nav-item.active {
    text-align: center;
    border-left: none;
  }
  .d-sidebar-item.d-nav-item.active{
    font-weight: 500;
    background-color: rgba(255, 255, 255, .05);
  }
  #topbar {
    display: flex;
  }
  .d-page-title {
    text-align: center;
  }
  .d-page-title-icon {
    display: none;
  }
}