.wrapper {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wrapper::before,
.wrapper::after {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  width: -webkit-fill-available;
}

.inner {
  padding: 10px 20px;
  display: inline-block;
  white-space: nowrap;
}